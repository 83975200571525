<template>
  <v-card>
    <v-card-title>
      {{ resident.name }}
    </v-card-title>
    <v-list>
      <v-list-item
        link
        :to="{
          name: 'manageResidentsInfo', params: {resident}
        }"
      >
        <v-list-item-content>
          <v-list-item-title> {{ $t("systemMenu.manage_residents.manage_info") }} </v-list-item-title>
          <v-list-item-subtitle> {{ $t("systemMenu.manage_residents.manage_info_description") }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        :to="{
          name: 'manageResidentsFiles', params: {resident}
        }"
      >
        <v-list-item-content>
          <v-list-item-title> {{ $t("systemMenu.manage_residents.manage_files") }} </v-list-item-title>
          <v-list-item-subtitle> {{ $t("systemMenu.manage_residents.manage_files_description") }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list-item @click="deleteResident()">
        <v-list-item-content>
          <v-list-item-title>
            {{ $t("systemMenu.manage_residents.delete_resident") }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $t("systemMenu.manage_residents.delete_resident_description") }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-dialog v-model="dialogDeleteResident" persistent max-width="560px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("systemMenu.manage_residents.delete_resident_confirm")
        }}</v-card-title>
      <v-card-text>
          {{ $t("systemMenu.manage_residents.delete_resident_description_dialog") }}
      </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDeleteResident = false"
            >{{ $t("systemMenu.manage_residents.cancel") }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteResidentConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="serverErrorDialog" persistent max-width="560px">
      <v-card>
        <v-card-title class="text-h5">{{
          $t("systemMenu.manage_residents.server_error")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="serverErrorDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { ILLI_API_SERVER_URL } from "../config";

export default {
  mounted() {
    this.resident = this.$route.params.resident
    if (this.resident?.id === undefined)
    {
        this.$router.push('residents')
    }
  },
  data() {
    return {
      resident: {},
      dialogDeleteResident: false,
      serverErrorDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      system: "system/system",
      user: "auth/user",
    }),
  },
  methods: {
    deleteResident() {
      this.dialogDeleteResident = true;
    },
    deleteResidentConfirm() {
      axios
        .delete(`${ILLI_API_SERVER_URL}/residents/${this.resident.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$router.push("residents");
          }
        })
        .catch(() => {
          this.serverErrorDialog = true;
        });

      this.dialogDeleteResident = false;
    },
  },
};
</script>